<template>
  <span>
    <!--TEXT & LACRE-->
    <v-text-field
      v-if="
        value.structure.structure_type === 'TEXT' ||
        value.structure.structure_type === 'LACRE' ||
        value.structure.structure_type === 'QR'
      "
      v-model="value.recordStructureValue.record_value[0]"
      :class="value.structure.is_required === true ? 'required' : ''"
      :clearable="true"
      color="primary"
      persistent-hint
      small
      flat
      outlined
      dense
      onkeypress="return (event.charCode != 34)"
      @input="$emit('bug_refresh', value.structure.is_required)"
    >
      <template v-slot:label>
        {{ translate(value.structure.language_key, true) }}
      </template>
    </v-text-field>

    <!--LOTE-->
    <v-text-field
      v-if="value.structure.structure_type === 'LOTE'"
      v-model="value.recordStructureValue.record_value[0]"
      :class="value.structure.is_required === true ? 'required' : ''"
      :clearable="true"
      :rules="
        lotRulesRequired(
          value.structure.is_required === false,
          value.structure.structure_type
        )
      "
      persistent-hint
      small
      flat
      outlined
      dense
      color="primary"
      onkeypress="return (event.charCode != 34)"
      @input="$emit('bug_refresh', value.structure.is_required)"
    >
      <template v-slot:label>
        {{ translate(value.structure.language_key, true) }}
      </template>
    </v-text-field>

    <!--NUMBER-->
    <v-currency-field
      :default-value="null"
      :value-as-integer="false"
      v-if="value.structure.structure_type === 'NUMBER'"
      :ref="'currency' + refKey"
      v-model="value.recordStructureValue.record_value[0]"
      :auto-decimal-mode="false"
      :class="value.structure.is_required === true ? 'required' : ''"
      :clearable="true"
      :decimal-length="decimalFormat(value)"
      color="primary"
      locale="en"
      persistent-hint
      small
      flat
      outlined
      dense
      onkeypress="return (event.charCode != 34)"
      @input="$emit('bug_refresh', value.structure.is_required)"
    >
      <template v-slot:label>
        {{ translate(value.structure.language_key, true) }}
      </template>
    </v-currency-field>

    <span v-if="isGroup">
      <!--NOMENCLATORSIMPLE OR NOMENCLATORMULTIPLE-->
      <v-autocomplete
          v-if="
          value.structure.structure_type === 'PRODUCTSIMPLE' ||
          value.structure.structure_type === 'EQUIPMENTSIMPLE' ||
          value.structure.structure_type === 'OPERATORSIMPLE' ||
          value.structure.structure_type === 'INPUTSIMPLE' ||
          value.structure.structure_type === 'SEASONSIMPLE' ||
          value.structure.structure_type === 'MEASUREMENTUNITSIMPLE' ||
          value.structure.structure_type === 'PRODUCT' ||
          value.structure.structure_type === 'EQUIPMENT' ||
          value.structure.structure_type === 'OPERATOR' ||
          value.structure.structure_type === 'INPUT' ||
          value.structure.structure_type === 'SEASON' ||
          value.structure.structure_type === 'MEASUREMENTUNIT'
        "
          v-model="value.recordStructureValue.record_value[0]"
          :clearable="true"
          :items="
          translateProcessDataAdd(value.structure.dataToSelect, value.structure)
        "
          :no-data-text="$t('general.fields.noDataAvailable')"
          :return-object="false"
          color="primary"
          item-color="primary"
          item-text="name"
          item-value="name"
          small-chips
          persistent-hint
          small
          flat
          outlined
          dense
          append-icon="mdi-magnify"
          @input="$emit('bug_refresh', value.structure.is_required)"
          @update:search-input="searchNomenclator($event, value)"
      >
        <!-- Slot para el footer del desplegable -->
        <template v-slot:append-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content
                class="d-flex flex-nowrap flex-row justify-space-between"
            >
              <v-list-item-subtitle class="text-start"
              ><strong>Total:</strong>
                {{ value.structure.totalDataToSelect }}</v-list-item-subtitle
              >
              <v-list-item-subtitle class="text-end"
              ><strong>Encontrados:</strong>
                {{ value.structure.partialDataToSelect }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:label>
          {{ translate(value.structure.language_key, true) }}
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0" small>
            <span>{{ item.name | truncate(50) }}</span>
          </v-chip>
        </template>
        <template v-slot:item="{ item, attrs }">
          <div style="max-width: 300px">
            <read-more
                :classes="'mb-0'"
                :font-size="14"
                :max-chars="80"
                :text="item.name"
            />
          </div>
        </template>
      </v-autocomplete>

      <!--LISTSIMPLE-->
      <v-autocomplete
          v-if="
          value.structure.structure_type === 'LISTSIMPLE' ||
          value.structure.structure_type === 'LIST'
        "
          v-model="value.recordStructureValue.record_value[0]"
          :clearable="true"
          :items="
          translateProcessDataAdd(value.structure.data_source, value.structure)
        "
          :return-object="false"
          color="primary"
          item-text="name"
          item-value="name"
          persistent-hint
          small
          flat
          outlined
          dense
      >
        <template v-slot:label>
          {{ translate(value.structure.language_key, true) }}
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0" small>
            <span>{{ item.name | truncate(50) }}</span>
          </v-chip>
        </template>
        <template v-slot:item="{ item, attrs }">
          <div style="max-width: 360px">
            <read-more
                :classes="'mb-0'"
                :font-size="14"
                :max-chars="80"
                :text="item.name"
            />
          </div>
        </template>
      </v-autocomplete>
    </span>
    <span v-else>
      <!--NOMENCLATORMULTIPLES-->
      <v-autocomplete
          v-if="
          value.structure.structure_type === 'PRODUCT' ||
          value.structure.structure_type === 'EQUIPMENT' ||
          value.structure.structure_type === 'OPERATOR' ||
          value.structure.structure_type === 'INPUT' ||
          value.structure.structure_type === 'SEASON' ||
          value.structure.structure_type === 'MEASUREMENTUNIT'
        "
          v-model="value.recordStructureValue.record_value"
          :clearable="true"
          :items="
          translateProcessDataAdd(value.structure.dataToSelect, value.structure)
        "
          :no-data-text="$t('general.fields.noDataAvailable')"
          :return-object="false"
          color="primary"
          item-color="primary"
          item-text="name"
          item-value="name"
          small-chips
          persistent-hint
          small
          flat
          outlined
          dense
          append-icon="mdi-magnify"
          multiple
          @input="$emit('bug_refresh', value.structure.is_required)"
          @update:search-input="searchNomenclator($event, value)"
      >
        <!-- Slot para el footer del desplegable -->
        <template v-slot:append-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content
                class="d-flex flex-nowrap flex-row justify-space-between"
            >
              <v-list-item-subtitle class="text-start"
              ><strong>Total:</strong>
                {{ value.structure.totalDataToSelect }}</v-list-item-subtitle
              >
              <v-list-item-subtitle class="text-end"
              ><strong>Encontrados:</strong>
                {{ value.structure.partialDataToSelect }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:selection="{ item, index }">
        <v-chip small v-if="index === 0">
          <span>{{ item.name | truncate(15) }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text caption"
        >(+{{ value.recordStructureValue.record_value[0].length - 1 }}
        {{ $t('general.titles.others') }})</span
        >
      </template>
        <template v-slot:item="{ item, attrs }">
          <div style="max-width: 360px">
            <v-checkbox
                :input-value="attrs.inputValue"
                color="primary"
                class="pt-2 pb-2 mt-0"
                :hide-details="true"
            >
              <template v-slot:label>
                <read-more
                    :classes="'mb-0'"
                    :text="item.name"
                    :max-chars="80"
                    :font-size="14"
                />
              </template>
            </v-checkbox>
          </div>
        </template>
        <template v-slot:label>
          {{ translate(value.structure.language_key, true) }}
        </template>
      </v-autocomplete>

      <!--NOMENCLATORSIMPLE-->
      <v-autocomplete
          v-if="
          value.structure.structure_type === 'PRODUCTSIMPLE' ||
          value.structure.structure_type === 'EQUIPMENTSIMPLE' ||
          value.structure.structure_type === 'OPERATORSIMPLE' ||
          value.structure.structure_type === 'INPUTSIMPLE' ||
          value.structure.structure_type === 'SEASONSIMPLE' ||
          value.structure.structure_type === 'MEASUREMENTUNITSIMPLE'
        "
          v-model="value.recordStructureValue.record_value[0]"
          :clearable="true"
          :items="
          translateProcessDataAdd(
            value.structure.dataToSelect,
            value.structure
          )
        "
          :no-data-text="$t('general.fields.noDataAvailable')"
          :return-object="false"
          color="primary"
          item-color="primary"
          item-text="name"
          item-value="name"
          persistent-hint
          small
          flat
          outlined
          dense
          small-chips
          @input="$emit('bug_refresh', value.structure.is_required)"
          @update:search-input="searchNomenclator($event, value)"
      >
        <template v-slot:label>
          {{ translate(value.structure.language_key, true) }}
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0" small>
            <span>{{ item.name | truncate(50) }}</span>
          </v-chip>
        </template>
        <template v-slot:item="{ item, attrs }">
          <div style="max-width: 300px">
            <read-more
                :classes="'mb-0'"
                :font-size="14"
                :max-chars="80"
                :text="item.name"
            />
          </div>
        </template>
      </v-autocomplete>

      <!--LIST-->
      <v-autocomplete
          :ref="`LIST${refKey}`"
          v-if="value.structure.structure_type === 'LIST'"
          :no-data-text="$t('general.fields.noDataAvailable')"
          v-model="value.recordStructureValue.record_value"
          small-chips
          :clearable="true"
          :return-object="false"
          item-text="name"
          item-value="name"
          :items="
          translateProcessDataAdd(value.structure.data_source, value.structure)
        "
          color="primary"
          item-color="primary"
          class="required"
          persistent-hint
          small
          flat
          outlined
          dense
          multiple
      >
        <template v-slot:label>
          {{ translate(value.structure.language_key, true) }}
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip small v-if="index === 0">
            <span>{{ item.name | truncate(15) }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text caption"
          >(+{{ value.recordStructureValue.record_value.length - 1 }}
            {{ $t("general.titles.others") }})</span
          >
        </template>
        <template v-slot:item="{ item, attrs }">
          <div style="max-width: 360px">
            <v-checkbox
                :input-value="attrs.inputValue"
                color="primary"
                class="pt-2 pb-2 mt-0"
                :hide-details="true"
            >
              <template v-slot:label>
                <ReadMore
                    :classes="'mb-0'"
                    :text="item.name"
                    :max-chars="80"
                    :font-size="14"
                />
              </template>
            </v-checkbox>
          </div>
        </template>
        <template v-slot:prepend-item>
          <v-checkbox
              v-model="checkboxState"
              color="primary"
              class="pt-2 pb-2 mt-0 pl-4"
              :hide-details="true"
              persistent-hint
              small
              flat
              outlined
              dense
              @change="selectAll(value.structure.structure_type)"
          >
            <template v-slot:label>
              <v-btn small color="primary" x-small text>
                {{ $t("general.titles.selectAll") }}
              </v-btn>
            </template>
          </v-checkbox>
          <v-divider class="mb-2"></v-divider>
        </template>
      </v-autocomplete>

      <!--LISTSIMPLE-->
      <v-autocomplete
          v-if="value.structure.structure_type === 'LISTSIMPLE'"
          v-model="value.recordStructureValue.record_value[0]"
          :clearable="true"
          :items="
          translateProcessDataAdd(value.structure.data_source, value.structure)
        "
          :return-object="false"
          color="primary"
          item-text="name"
          item-value="name"
          persistent-hint
          small
          flat
          outlined
          dense
      >
        <template v-slot:label>
          {{ translate(value.structure.language_key, true) }}
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0" small>
            <span>{{ item.name | truncate(50) }}</span>
          </v-chip>
        </template>
        <template v-slot:item="{ item, attrs }">
          <div style="max-width: 360px">
            <read-more
                :classes="'mb-0'"
                :font-size="14"
                :max-chars="80"
                :text="item.name"
            />
          </div>
        </template>
      </v-autocomplete>
    </span>

    <!--DATETIME-->
    <a-datetime-picker
      v-model="value.recordStructureValue.record_value[0]"
      v-if="value.structure.structure_type === 'DATATIME'"
      :label="translate(value.structure.language_key, true)"
      :format="'dd / MM / yyyy HH:mm'"
      :textFieldProps="{
        'append-icon': 'mdi-calendar',
        'color': 'secondary',
        'outlined': true,
        'dense': true,
        'clearable': true,
      }"
      :datePickerProps="{
        'color': 'secondary'
      }"
      :timePickerProps="{
        'color': 'secondary'
      }"
    />
    <!--<v-datetime-picker
      v-if="value.structure.structure_type === 'DATATIME'"
      :ref="'datatime' + refKey"
      v-model="value.recordStructureValue.record_value[0]"
      @update="$set(value.recordStructureValue.record_value, 0, $event)"
      :label="translate(value.structure.language_key, true)"
      :locale="$i18n.locale"
      :show-date="true"
      :show-time="true"
      :text-field-props="textProps"
      color="primary"
      date-format="dd / MM / yyyy"
      item-color="primary"
    />-->

    <!--DATE-->
    <a-date-picker  v-if="value.structure.structure_type === 'DATA'"
                    v-model="value.recordStructureValue.record_value[0]"
                    :textFieldProps="{ outlined: true, label: translate(value.structure.language_key, true) }"
                    :label="translate(value.structure.language_key, true)"
    />
    <!--<v-datetime-picker
      v-if="value.structure.structure_type === 'DATA'"
      :ref="'data' + refKey"
      v-model="value.recordStructureValue.record_value[0]"
      @update="$set(value.recordStructureValue.record_value, 0, $event)"
      :label="translate(value.structure.language_key, true)"
      :locale="$i18n.locale"
      :show-date="true"
      :show-time="false"
      :text-field-props="textProps"
      color="primary"
      date-format="dd / MM / yyyy"
      item-color="primary"
    />-->

    <!--TIME-->
    <a-time-picker v-if="value.structure.structure_type === 'TIME'"
                   v-model="value.recordStructureValue.record_value[0]"
                   :textFieldProps="{ outlined: true, label: translate(value.structure.language_key, true) }"
                   :label="translate(value.structure.language_key, true)"
    />
    <!--<v-datetime-picker
      v-if="value.structure.structure_type === 'TIME'"
      :ref="'time' + refKey"
      v-model="value.recordStructureValue.record_value[0]"
      @update="$set(value.recordStructureValue.record_value, 0, $event)"
      :dialog-width="290"
      :label="translate(value.structure.language_key, true)"
      :locale="$i18n.locale"
      :show-date="false"
      :show-time="true"
      :text-field-props="textProps"
      color="primary"
      item-color="primary"
      time-format="HH:mm"
    />-->
  </span>
</template>

<script>
  import _ from "lodash"
  import axios from "axios"
  import { mapActions, mapGetters } from "vuex"
  import sortList from "@/services/OrderListService"
  import LanguajeService from "@/services/LanguajeService"
  import ReadMore from "@/components/ReadMoreComponent"
  import NomenclatorComponent from "@/module/records/components/NomenclatorComponent";
  import ADatetimePicker from "@/components/common/ADatetimePicker.vue";
  import ADatePicker from "@/components/common/ADatePicker.vue";
  import ATimePicker from "@/components/common/ATimePicker.vue";

  export default {
    name: "SingleStructureComponent",
    components: {ATimePicker, ADatePicker, ADatetimePicker, NomenclatorComponent, ReadMore },

    props: {
      nomenclators: {
        type: Array,
        required: false,
        default: () => {
          return []
        },
      },
      isGroup: {
        type: Boolean,
        required: true
      },
      refKey: {
        type: Number|String,
        required: false,
      },
      value: {
        type: Object,
        required: true,
        default: () => {
          return {}
        },
      },
      valid: {
        type: Boolean,
        required: true,
      },
      config: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        source: null,
        textProps: {
          persistentHint: true,
          small: true,
          flat: true,
          outlined: true,
          dense: true,
          clearable: true,
          color: "secondary",
          itemColor: "secondary",
        },
        noDataText: "",
        searchInput: "",
        checkboxState: false,
      }
    },

    async mounted() {
      this.clearDatetimeField()

      this.$root.$on("clearDatetimeField", () => {
        this.clearDatetimeField()
      })

      this.$root.$on("searchNomenclator", (params) => {
        this.searchNomenclator(...params)
      })

      if (this.nomenclators.includes(this.value.structure.structure_type))
        await this.searchProcessStructure([
          this.value.recordStructureValue.record_value,
          this.value.recordStructureValue.processStructureId.id,
          this.source,
        ])
            .then(async res => {
              await this.$emit('update:structure', {
                dataToSelect: res.dataToSelect,
                ...this.value.structure,
                ...res,
              })
            })
            .catch(console.log)
    },

    computed: {
      ...mapGetters({
        requireRules: "general/requireRules",
        selectRule: "general/selectRule",
        requireAndLotRules: "general/requireAndLotRules",
        lotRules: "general/lotRules",
      }),
      decimalFormat() {
        return value =>
          !["CI", "CO", "C"].includes(value.structure.structureCode) &&
          (value.structure.afterPoint !== null ||
            value.structure.afterPoint !== "-")
            ? value.structure.afterPoint
            : this.config.company.traceability.structure.number.afterPoint !==
              null
            ? parseInt(
                this.config.company.traceability.structure.number.afterPoint
              )
            : 3
      },
    },

    methods: {
      ...mapActions({
        searchProcessStructure: "proccess/searchProcessStructure",
      }),
      selectAll(type){
        if( this.checkboxState === false ){
          this.value.recordStructureValue.record_value = [];
        } else {
          let aux = [];
          if(this.value.recordStructureValue.record_value.length > 0 ) {
            const names = this.getByProperty(this.$refs[`${type}${this.refKey}`].filteredItems, 'name')
            _.forEach(names, (name) => {
              if (!this.value.recordStructureValue.record_value.includes(name))
                this.value.recordStructureValue.record_value.push(name)
            })
            aux = this.value.recordStructureValue.record_value;
          }else{
            aux = this.getByProperty(this.$refs[`${type}${this.refKey}`].filteredItems, 'name');
          }

          this.$set(this.value.recordStructureValue, "record_value", aux);
        }
      },
      getByProperty(array, property) {
        return _.map(array, property)
      },
      clearDatetimeField() {
        switch (this.value.structure.structure_type) {
          case "DATATIME":
            if (this.$refs["datatime" + this.refKey] instanceof Array) {
              _.forEach(this.$refs["datatime" + this.refKey], ref => {
                // ref.clearDateTime()
              })
            } else {
              // this.$refs["datatime" + this.refKey].clearDateTime()
            }
            break
          case "DATA":
            if (this.$refs["data" + this.refKey] instanceof Array) {
              _.forEach(this.$refs["data" + this.refKey], ref => {
                // ref.clearDate()
              })
            } else {
              // this.$refs["data" + this.refKey].clearDate()
            }
            break
          case "TIME":
            if (this.$refs["time" + this.refKey] instanceof Array) {
              _.forEach(this.$refs["time" + this.refKey], ref => {
                // ref.clearTime()
              })
            } else {
              // this.$refs["time" + this.refKey].clearTime()
            }
            break
        }
      },
      async searchNomenclator(e, object) {
        if (!object) {
          this.noDataText = ""
          return
        }

        if (this.source) this.source.cancel()

        const CancelToken = axios.CancelToken
        this.source = CancelToken.source()

        if (e) {
          await this.searchProcessStructure([
            [e],
            object.recordStructureValue.processStructureId.id,
            this.source,
          ])
            .then(async res => {
              await this.$emit('update:structure', {
                dataToSelect: res.dataToSelect,
                ...object.structure,
                ...res,
              })
            })
            .catch(console.log)

          this.source = null
        }
      },
      lotRulesRequired(required, structureType) {
        if (required && structureType === "LOTE") {
          return this.requireAndLotRules
        } else if (required) {
          return this.requireRules
        } else {
          return this.lotRules
        }
      },
      translate(key) {
        return LanguajeService.getKey3(key)
      },
      translateProcessDataAdd(data, structure) {
        if (
          ["SEASON", "SEASONSIMPLE", "LIST", "LISTSIMPLE"].includes(
            structure.structure_type
          )
        ) {
          return sortList.orderNumbersAndLettersList(
            _.map(data, details => {
              return {
                ...details,
                name: LanguajeService.getKey3(details.name),
              }
            })
          )
        } else {
          return sortList.orderListByUppercase(
            _.map(data, details => {
              return {
                ...details,
                name: LanguajeService.getKey3(details.name),
              }
            }),
            "name"
          )
        }
      },
    },

    destroyed() {
      this.$root.$off("clearDatetimeField")
      this.$root.$off("searchNomenclator")
    },
  }
</script>

<style scoped></style>
